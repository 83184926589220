/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import WhatsAppButton from "components/ButtonBottomRight/WhatsAppButton.js";
import backgroundImage from 'assets/img/theme/simple_bg_green.jpg';

function AboutUs() {

  const handleOpenWaze = () => {
    const latitude = 5.399225417605643; // Replace with the desired latitude of the location
    const longitude = 100.39909380470225; // Replace with the desired longitude of the location
    const url = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

    window.open(url, '_blank');
  };

  return (
    <>
      <WhatsAppButton></WhatsAppButton>
      <IndexNavbar />
      <div className="main-content">
        <section className="py-6 pb-9" style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: "0% 70%"}}>
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h1 className="display-2 text-white font-weight-bold mb-0">
                  About Us
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                          <i className="ni ni-mobile-button" />
                        </div>
                        <h4 className="h3 text-info text-uppercase">
                          Contact Us
                        </h4>
                        <p className="description mt-3">
                          Whatsapp/Call
                          014-6678869
                        </p>
                        <br></br>
                        <div>
                          <Badge color="info" pill>
                            Chat
                          </Badge>
                          <Badge color="info" pill>
                            Call
                          </Badge>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-square-pin" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                          LOKASI
                        </h4>
                        <p className="description mt-3">
                          No1 Tingkat 1 Jalan Todak 2 Bandar Seberang Jaya 13700 Perai Pulau Pinang
                        </p>
                        <div>
                          <Badge color="success" pill>
                            Prai
                          </Badge>
                          <Badge color="success" pill>
                            
                          </Badge>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                          <i className="ni ni-send" />
                        </div>
                        <h4 className="h3 text-warning text-uppercase">
                          Our Email
                        </h4>
                        <p className="description mt-3">
                          ascredit44@gmail.com
                        </p>
                        <br></br>
                        <div>
                          <Badge color="warning" pill>
                            Gmail
                          </Badge>
                          <Badge color="warning" pill>
                            Outlook
                          </Badge>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        
        <section className="py-6">
          <Container>
            <Row className="row-grid">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/shop_loc1.jpg").default}
                />
              </Col>
              <Col md="6">
                <div className="pr-md-5">
                  <h1>LOKASI</h1>
                  <h3 style={{textAlign:"justify"}}>
                    No1 Tingkat 1 Jalan Todak <br></br>2 Bandar Seberang Jaya <br></br>13700 Perai Pulau Pinang
                  </h3>
                  <br></br>
                  <h3>
                    You can find us in Waze
                  </h3>
                  <Button color="info" onClick={handleOpenWaze}>Open in Waze</Button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <h1>HUBUNGI</h1>
                  <h3>
                    014-6678869
                  </h3>
                  <h3>
                    ascredit44@gmail.com
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <AuthFooter />
    </>
  );
}

export default AboutUs;
