/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import WhatsAppButton from "components/ButtonBottomRight/WhatsAppButton.js";
import backgroundImage from 'assets/img/theme/simple_bg_green.jpg';

function Index() {

  const handleButtonClick = () => {
    const message = 'Hello, Anseng Credit Sdn Bhd!';

    // Replace the "1234567890" with the recipient's phone number or WhatsApp ID
    const phoneNumber = '+60146678869';

    // Create the WhatsApp web URL with the pre-filled message
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new tab
    window.open(url, '_blank');
  };

  return (
    <>
      <WhatsAppButton></WhatsAppButton>
      <IndexNavbar />
      <div className="main-content">
        <IndexHeader />
        
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-money-coins" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                          Tiada Yuran Pendahuluan
                        </h4>
                        <p className="description mt-3">
                          Kami tidak mengenakan sebarang caj atau deposit 
                          semasa proces permohonan berjalan sehingga permohonan lulus
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-bulb-61" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                        Pelan Bayaran Balik Fleksibel
                        </h4>
                        <p className="description mt-3">
                          Pinjaman dan bayaran balik secara ansuran setiap bulan adalah tetap
                          dan rendah berbanding syarikat lain
                        </p>
                        <br></br>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-delivery-fast" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                          Kelulusan Pantas <br></br> (1 Jam)
                        </h4>
                        <p className="description mt-3">
                          Procedure mudah dan cepat, pinjaman boleh diluluskan dalam masa SATU JAM.
                        </p>
                        <br></br>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                          <i className="ni ni-cloud-download-95" />
                        </div>
                        <h4 className="h3 text-success text-uppercase">
                          Pelan Pinjaman Kadar Rendah
                        </h4>
                        <p className="description mt-3">
                          Pinjaman minima rendah untuk menyelesaikan masalah kewangan buat sementara.
                        </p>
                        <br></br>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/money_img1.jpeg").default}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h1>Pinjaman Peribadi</h1>
                  <p>
                    Adakah anda memerlukan dana untuk membiayai perbelanjaan peribadi anda seperti pengubahsuaian rumah, bil perubatan, perbelanjaan percutian, kos pendidikan, kos perkahwinan, atau menggabungkan hutang anda dengan hanya satu bayaran balik?
                    Pinjaman peribadi boleh memberi anda cara penyelesaian kewangan yang anda perlukan. Hubungi kami untuk mengetahui cara kami boleh membantu anda.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">RM500 – RM50,000</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Tempoh bayaran: 6 Bulan – 5 Tahun</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Kelulusan 1 jam</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Bayaran bulanan serendah RM62/bulan sahaja</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/money_img2.jpeg").default}
                />
              </Col>
              <Col md="6">
                <div className="pr-md-5">
                  <h1>Pinjaman Perniagaan</h1>
                  <p>
                    Untuk meningkatkan aliran tunai perniagaan anda, belanjakan modal perniagaan anda yang ada, selesaikan tunggakan pembayaran pembekal dan lain-lain. Kami menawarkan pinjaman yang cepat & boleh dipercayai, dengan tempoh pembayaran yang lebih lama & proses tanpa halangan.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">RM5,000 – RM100,000</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Tempoh bayaran: 6 Bulan – 5 Tahun</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Kelulusan 1 jam</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Bayaran bulanan serendah RM200/bulan sahaja</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>
      <AuthFooter />
    </>
  );
}

export default Index;
