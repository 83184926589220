import { 
  CardHeader,
  Card, 
  CardBody,
  Container,
  Row,
  Col,
  FormGroup, 
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button
} from "reactstrap";
import React from "react";
import classnames from "classnames";
import backgroundImage from 'assets/img/theme/simple_bg_green.jpg';
import headerImage from 'assets/img/theme/hdr_image.png';
import headerImageMobile from 'assets/img/theme/shop_loc1.jpg';
import ReactBSAlert from "react-bootstrap-sweetalert";

function IndexHeader() {
  const [yourName, setyourName] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState("");
  const [phoneNumber, setphoneNumber] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  
  const userAgent = window.navigator.userAgent;
  const mobileKeywords = ['Mobile', 'iPhone', 'Android', 'Windows Phone'];
  const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Adjust the breakpoint as per your design
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial view on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageMobileStyle = {
    width: '300px', // Adjust the width as needed
    height: '200px', // Adjust the height as needed
  };

  const imageDesktopStyle = {
    width: '500px', // Adjust the width as needed
    height: '450px', // Adjust the height as needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // if (!phoneNumber || !yourName) {
    //   setAlert(
    //     <ReactBSAlert
    //       danger
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Not able to send"
    //       onConfirm={() => setAlert(null)}
    //       onCancel={() => setAlert(null)}
    //       confirmBtnBsStyle="primary"
    //       confirmBtnText="Ok"
    //       btnSize=""
    //     >
    //       Please fill in required field
    //     </ReactBSAlert>
    //   );
    //   return;
    // }else{

      const message = `Nama : ${yourName}\nPhone : ${phoneNumber}\nLokasi : ${location}\ Pinjanman : ${totalAmount}`;
      const phoneNumber = '+60146678869'
      
      let url;

      if (isMobile) {
        // Mobile version
        url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      } else {
        // PC version
        url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      }

      // Open WhatsApp in a new tab
      window.open(url, '_blank');
    // }
  };
  
  return (
    <>
      {alert}
      <div className="header pt-5 pb-7" style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: "0% 70%"}}>
        <Container>
          <div className="header-body">
            <Row style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <p className="display-1" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white"}}>
                  FAEDAH TERENDAH <br />
                  <p className="display-1" style={{ marginLeft: "20px" }}>DALAM PASARAN</p>
                </p>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Row>
                  {window.innerWidth < 768 ? (
                    <div  className="pr-5" style={{marginLeft:'40px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                      <img src={headerImageMobile} style={imageMobileStyle} alt=""/>
                    </div>
                  ) : (
                    <div className="pr-5">
                      <img src={headerImage} style={imageDesktopStyle} alt=""/>
                    </div>
                  )}
                </Row>
              </Col>
              <Col lg="6">
                <Row className="pt-5">
                  <Col md="10">
                    <Card>
                      <CardHeader>
                        <h3 className="mb-0">Mohon Sekarang</h3>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <Row>
                          <Col md="12">
                              <FormGroup>
                                <InputGroup
                                  className={classnames("input-group-merge", {
                                    focused: yourName,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-user" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="NAMA *"
                                    type="text"
                                    onChange={(e) => setyourName(e.target.value)}
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <InputGroup
                                  className={classnames("input-group-merge", {
                                    focused: phoneNumber,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                    <i className="fas fa-phone" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="NO. TELEFON *"
                                    type="text"
                                    onChange={(e) => setphoneNumber(e.target.value)}
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <InputGroup
                                  className={classnames("input-group-merge", {
                                    focused: totalAmount,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-credit-card" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="PINJAMAN"
                                    onChange={(e) => setTotalAmount(e.target.value)}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <InputGroup
                                  className={classnames("input-group-merge", {
                                    focused: location,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-square-pin" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="LOKASI"
                                    onChange={(e) => setLocation(e.target.value)}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <Button
                                style={{marginLeft: 'auto',display: 'flex',justifyContent: 'center', width: '100%', textAlign:'center'}}
                                color="info"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                MOHON SEKARANG
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <section>
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h1 style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white"}} className="display-2 font-weight-bold mb-0">
                  PERKHIDMATAN KAMI
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default IndexHeader;
