
import { NavItem, NavLink, Nav, Container, Row, Col,  UncontrolledTooltip,Button,Badge} from "reactstrap";
import backgroundImage from 'assets/img/theme/simple_bg_blue_footer.jpg';

function AuthFooter() {

  const userAgent = window.navigator.userAgent;
  const mobileKeywords = ['Mobile', 'iPhone', 'Android', 'Windows Phone'];
  const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

  const handleButtonClick = () => {
    const message = 'Hello, Anseng Credit Sdn Bhd!';

    // Replace the "1234567890" with the recipient's phone number or WhatsApp ID
    const phoneNumber = '+60146678869';

    // Create the WhatsApp web URL with the pre-filled message
    let url;

    if (isMobile) {
      // Mobile version
      url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    } else {
      // PC version
      url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    }
    
    // Open WhatsApp in a new tab
    window.open(url, '_blank');
  };

  const btnStyle = {
    backgroundColor: 'transparent',
    border: '4px solid #000',
    color:'#000',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)' /* Define the shadow effect */
  };

  const iconStyle = {
    marginLeft: '5px',
    width: '180px', // Adjust the width as needed
    height: '180px', // Adjust the height as needed
  };

  const AnsengCreditSdnBhd = () => {

    if (isMobile) {
      return (
        <div>
          <h2  className="display-2" style={{textAlign: "center", color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white", marginTop:"30px", textAlign:"left"}}>
            Anseng Credit Sdn. Bhd.
          </h2>
        </div>
      );
    }else{
      return (
        <div>
          <h1  className="display-2" style={{textAlign: "center", color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white", marginTop:"30px", textAlign:"left"}}>
            Anseng Credit
          </h1>
          <h1  className="display-2" style={{textAlign: "center", color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white", marginLeft:"45px", textAlign:"left"}}>
            Sdn. Bhd.
          </h1>
        </div>
      );
    }
  };

  const currentDate = new Date();
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString(undefined, options);

  return (
    <>
        <footer className="py-5" id="footer-main" 
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "0% 70%"
          }}
          >
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" xl="6">
                <h1 className="display-1" style={{color:'#000', textAlign:"center"}}>
                  Dapatkan pinjaman segera dalam masa 1 jam.
                </h1>
                <Button
                  style={btnStyle}
                  className="btn-neutral btn-icon"
                  onClick={handleButtonClick}
                >
                  <span className="nav-link-inner--text" style={{ fontSize:'36px'}}>MOHON SEKARANG</span>
                </Button>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
              <Col style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <img
                  alt="..."
                  style={iconStyle}
                  src={require("assets/img/brand/shop-icon-3.png").default}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-xl-between">
              <Col lg="12">
                <Row>
                  <Col lg="4">
                    {AnsengCreditSdnBhd()}
                    <div>
                      <h2 className="display-3" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white", marginTop:"30px", marginBottom: '-5px' }}>
                        NO LESEN
                      </h2>
                      <br></br>
                      <span className="display-4" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white",}}>
                        WL4197/05/01-10/151224
                      </span>
                    </div>
                  </Col>
                  <Col lg="4" style={{marginTop:"30px", textAlign:"justify"}}>
                    <h1 className="display-2" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white",}}>
                      Tentang Kami
                    </h1>
                    <br></br>
                    <span style={{fontSize: "20px", color:"#000",}}>
                        Di bawah pengawasan  KPKT (Kredit Komuniti), Anseng Credit Sdn. Bhd. merupakan satu syarikat pinjaman wang
                        berlesen yang beroperasi di Seberang Jaya, Penang. Kami menawarkan pelbagai pelan pinjaman dengan kadar 
                        bayaran terendah dan fleksibel kepada semua peminjam dari sektor kerajaan, swasta (termasuk kilang), 
                        penerima pencen dan perkeso (SOCSO).
                    </span>
                  </Col>
                  <Col lg="4">
                    <h1  className="display-2" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white", marginTop:"30px"}}>
                      Hubungi Kami
                    </h1>
                    <div>
                      <h2  className="display-3" style={{color:"#000", textShadow: "1px 1px 0 white, -1px -1px 0 white", marginTop:"30px", marginBottom: '-5px'}}>
                        NO TELEFON
                      </h2>
                      <span  className="display-4" style={{color:"#000", textShadow: "1px 1px 0 white, -1px -1px 0 white",}}>
                        016-4444399<br></br>
                        04-3976788
                      </span>
                    </div>
                    <div>
                      <h2 className="display-3" style={{color:'#000', textShadow: "1px 1px 0 white, -1px -1px 0 white",marginTop:"30px",  marginBottom: '-5px'}}>
                        ALAMAT
                      </h2>
                      <span  className="display-4" style={{color:"#000", textShadow: "1px 1px 0 white, -1px -1px 0 white",}}>
                        No1 Tingkat 1 Jalan Todak 2 Bandar Seberang Jaya 13700 Perai Pulau Pinang
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footer>
    </>
  );
}

export default AuthFooter;
