
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import whatsappIcon from './WhatsApp_logo.png';

function AdminNavbar() {

  
  const userAgent = window.navigator.userAgent;
  const mobileKeywords = ['Mobile', 'iPhone', 'Android', 'Windows Phone'];
  const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

  
  const handleButtonClick = () => {
    const message = 'Hello, Anseng Credit Sdn Bhd!';

    // Replace the "1234567890" with the recipient's phone number or WhatsApp ID
    const phoneNumber = '+60146678869';

    // Create the WhatsApp web URL with the pre-filled message
    let url;
    if (isMobile) {
      // Mobile version
      url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    } else {
      // PC version
      url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    }

    // Open WhatsApp in a new tab
    window.open(url, '_blank');
  };

  const iconStyle = {
    marginRight: '5px',
    width: '50px', // Adjust the width as needed
    height: '50px', // Adjust the height as needed
  };

  const BtnIconStyle = {
    marginRight: '5px',
    width: '20px', // Adjust the width as needed
    height: '20px', // Adjust the height as needed
  };

  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark "
        expand="lg"
        id="navbar-main"
        style={{backgroundColor: "#FFF"}}
        // style={{ backgroundImage: `url(${backgroundImage})`}}
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              style={iconStyle}
              src={require("assets/img/brand/shop-icon-3.png").default}
            />
            <span style={{color:"black", marginLeft:"5px", fontWeight:"bold"}}>Anseng Credit Sdn. Bhd.</span>
          </NavbarBrand>
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
            style={{backgroundColor:"#b4e31b"}}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/dashboard">
                    <img
                      alt="..."
                      src={require("assets/img/brand/shop-icon-1.png").default}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  <span className="nav-link-inner--text" style={{color:"black", fontWeight: "bold"}}>Home</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/anseng-credit/about-us" tag={Link}>
                  <span className="nav-link-inner--text" style={{color:"black",fontWeight: "bold"}}>About Us</span>
                </NavLink>
              </NavItem>
            </Nav>
            <hr className="d-lg-none" />
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem className="d-none d-lg-block ml-lg-4">
                <Button
                  style={{backgroundColor: '#33d951',borderColor: '#33d951',color:'#FFF'}}
                  className="btn-neutral btn-icon"
                  onClick={handleButtonClick}
                >
                  <img src={whatsappIcon} alt="WhatsApp Icon" style={BtnIconStyle} />
                  <span className="nav-link-inner--text">Find us on WhatsApp</span>
                </Button>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
