import React from 'react';
import {
  Button
} from "reactstrap";
import whatsappIcon from './WhatsApp_logo.png';

const WhatsAppButton = () => {

  const userAgent = window.navigator.userAgent;
  const mobileKeywords = ['Mobile', 'iPhone', 'Android', 'Windows Phone'];
  const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));
  
  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    padding: '10px 20px',
    backgroundColor: '#33d951',
    borderColor: '#33d951',
    borderRadius: '50px', // Set the border radius to make it rounded
    color: '#FFF', // Set the font color to white
    fontSize: '16px',
    cursor: 'pointer',
    zIndex: '9999', // Set a high z-index value
    right: '40px'
    /* Add more styles as needed */
  };

  const iconStyle = {
    marginRight: '5px',
    width: '20px', // Adjust the width as needed
    height: '20px', // Adjust the height as needed
  };

  const handleButtonClick = () => {
    const message = 'Hello, Anseng Credit Sdn Bhd!';

    // Replace the "1234567890" with the recipient's phone number or WhatsApp ID
    const phoneNumber = '+60146678869';

    let url;

    if (isMobile) {
      // Mobile version
      url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    } else {
      // PC version
      url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    }
    
    // Open WhatsApp in a new tab
    window.open(url, '_blank');
  };

  return (
    <Button style={buttonStyle} onClick={handleButtonClick}>
      <img src={whatsappIcon} alt="WhatsApp Icon" style={iconStyle} />
      <span>Contact Us</span>
    </Button>
  );
};

export default WhatsAppButton;